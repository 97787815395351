import BookTypes from './book';

export default interface InventoryTypes {
    isbn: string;
    source: string;
    book: BookTypes;
    expires: string;
    origin: InventoryOrigin;
    accessLabel?: string;
    expiresLabel?: string;
    expired?: string;
}

export enum InventoryOrigin {
    PURCHASED = 'BOUGHT',
    RENTED = 'RENTED',
    FREE_BOOK = 'FREE_BOOK',
    BOKSKYA = 'BOKSKYA',
    SUBSCRIPTION = 'SUBSCRIPTION',
}

export type SubscriptionPlanProfileTypes = {
    id: string;
    expires: string | null;
    plan_id: string;
    ruleset_id: string;
    start: string;
    subscription_plan: {
        id: string;
        plan_name: string;
    };
    user_id: string;
};
