import { CircularProgress, ListItemText, Menu, MenuItem, useTheme } from '@mui/material';
import { Link, useFetcher, useLocation } from '@remix-run/react';
import { Dispatch, SetStateAction, useRef, useState } from 'react';
import { RemoveBookModal } from '~/components/organisms/RemoveBookModal/RemoveBookModal';
import BookTypes from '~/types/app/book';
import { InventoryOrigin } from '~/types/app/inventory';

export const MoreBookOptions = ({
    bookTileMoreButtonRef,
    moreDropdown,
    setMoreDropdown,
    book,
    bookAccessType,
    removeBookSubmitUrl,
}: {
    bookTileMoreButtonRef: ReturnType<typeof useRef<HTMLButtonElement | null>>;
    moreDropdown: boolean;
    setMoreDropdown: Dispatch<SetStateAction<boolean>>;
    book: BookTypes;
    bookAccessType: string | undefined;
    removeBookSubmitUrl: string;
}) => {
    const location = useLocation();
    const theme = useTheme();

    const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
    const removeBookFetcher = useFetcher<any>();
    const { state, submit } = removeBookFetcher;

    const closeConfirmDialog = () => {
        setConfirmDialogOpen(false);
    };

    const removeBookHandler = async () => {
        submit({ action: 'removeBookFromBookshelf', isbn: book.isbn }, { method: 'post', action: removeBookSubmitUrl });
    };

    return (
        <>
            <Menu
                anchorEl={bookTileMoreButtonRef.current}
                open={moreDropdown}
                onClose={() => setMoreDropdown(false)}
                MenuListProps={{
                    'aria-labelledby': 'moreButton',
                }}
                sx={{
                    '.Mui-focusVisible': {
                        color: theme.palette.common.white,
                    },
                }}
            >
                <MenuItem
                    component={Link}
                    to={`${book.meta.url}/lese`}
                    state={{ from: location.pathname }}
                    aria-label="Åpne boka"
                >
                    <ListItemText sx={{ '.MuiTypography-root': { fontSize: 14 } }}>Åpne boka</ListItemText>
                </MenuItem>
                {(bookAccessType === InventoryOrigin.SUBSCRIPTION || bookAccessType === InventoryOrigin.FREE_BOOK) && (
                    <MenuItem
                        onClick={() => {
                            setConfirmDialogOpen(!confirmDialogOpen);
                        }}
                        data-cy={`cy-remove-book-from-bookshelf-${book.isbn}`}
                        aria-label="Fjern bok fra bokhylle"
                        sx={{ width: '175px', justifyContent: 'center' }}
                    >
                        {state === 'idle' ? (
                            <ListItemText sx={{ '.MuiTypography-root': { fontSize: 14 } }}>
                                Fjern bok fra bokhylle
                            </ListItemText>
                        ) : (
                            <CircularProgress size={18} sx={{ color: theme.palette.cta.main }} />
                        )}
                    </MenuItem>
                )}
            </Menu>
            <RemoveBookModal
                confirmDialogOpen={confirmDialogOpen}
                closeConfirmDialog={closeConfirmDialog}
                removeBookHandler={removeBookHandler}
                state={state}
            />
        </>
    );
};
